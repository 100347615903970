document.addEventListener("DOMContentLoaded", function () {
  const cnt = document.getElementById("tp-container");
  if (!cnt) return;

  function reqListener() {
    if (this.readyState !== 4) return;
    if (this.status !== 200 && this.status !== 201) return;
    const cnt = document.getElementById("tp-container");
    if (!cnt) return;
    cnt.innerHTML = this.responseText;
    const linkEls = cnt.getElementsByClassName("tp-read-more");
    for (let i = 0; i < linkEls.length; i++) {
      const linkEl = linkEls[i];
      linkEl.onclick = function () {
        const oEls = this.parentElement.getElementsByClassName("output");
        if (oEls && oEls.length) {
          oEls[0].classList.remove("truncated");
        }
      };
    }
  }

  let url = "/trustpilot-xhr.php?ts=" + Date.now();
  if (cnt.dataset.class) {
    url += "&class=" + cnt.dataset.class;
  }
  const oReq = new XMLHttpRequest();
  oReq.addEventListener("load", reqListener);
  oReq.open("GET", url);
  oReq.send();
});
